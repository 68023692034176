import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  // ListItem,
  // OrderedList,
  // UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../../layout/base'
import {
  Heading1,
  Heading2,
  // Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [word, setWord] = useState('Palabra')
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]
  useEffect(() => {
    setWord(
      selectRandomItem(
        data.wordsJson.english.nouns
          .concat(data.wordsJson.english.adjectives)
          .concat(data.wordsJson.english.verbs)
      )
    )
  }, [
    data.wordsJson.english.nouns,
    data.wordsJson.english.adjectives,
    data.wordsJson.english.verbs,
  ])

  return (
    <BaseLayout>
      <SEO
        title="Generador de adjetivos en inglés aleatorios"
        titleSeo="Generador de adjetivos en inglés aleatorios"
        description="Herramienta para generar adjetivos en inglés al azar."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[
          { name: 'Palabras', url: '/palabras/' },
          { name: 'Inglés', url: '/ingles/' },
        ]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de palabras en inglés aleatorias
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() =>
                setWord(
                  selectRandomItem(
                    data.wordsJson.english.nouns
                      .concat(data.wordsJson.english.adjectives)
                      .concat(data.wordsJson.english.verbs)
                  )
                )
              }
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de adjetivos en inglés aleatorios</Heading1>
        <Paragraph>Generar adjetivos al azar en inglés.</Paragraph>
        <Heading2>
          Herramienta para generar adjetivos en inglés aleatorios
        </Heading2>
        <Paragraph>Cómo generar adjetivos en inglés al azar.</Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      english {
        nouns
        adjectives
        verbs
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
